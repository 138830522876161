import React from "react";
import { RecoilRoot } from "recoil";

interface wrapperProps {
  element: any;
  props: any;
}

export const wrapRootElement = ({ element, props }: wrapperProps) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>;
};

/**
 * Pre-load fonts
 * Font Family:
 *  1. Open Sans
 *  2. Orbitron
 */
export const onRenderBody = ({ setHeadComponents }: any) => {
  setHeadComponents([
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-Bold.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansBold"
    />,
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-ExtraBold.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansExtraBold"
    />,
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-Light.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansLight"
    />,
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-Medium.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansMedium"
    />,
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-Regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansRegular"
    />,
    <link
      rel="preload"
      href="/fonts/Open_Sans/woff2/OpenSans-SemiBold.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="openSansSemiBold"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-Black.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronBlack"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-Bold.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronBold"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-ExtraBold.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronExtraBold"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-Medium.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronMedium"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-Regular.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronRegular"
    />,
    <link
      rel="preload"
      href="/fonts/Orbitron/static/Orbitron-SemiBold.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="orbitronSemiBold"
    />,
  ]);
};
